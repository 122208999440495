import React from 'react';

export default () => (
    <div className="descripciones">
        <div>
            <span className="circulo verde"></span>
            Area Excavacion
        </div>
        <div>
            <span className="circulo azul"></span>
            Area Exploracion
        </div>
    </div>
);
